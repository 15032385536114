import React, { useEffect, useState } from 'react';
import { Button, Modal, Upload, Input, Select, Form, Row, Col } from 'antd';
import { UploadOutlined, LinkOutlined,MinusCircleOutlined,PlusOutlined } from '@ant-design/icons';
//firebase import
import firebase, {ref, storage, db} from './Firebase';
import { uploadBytes, getDownloadURL } from 'firebase/storage';
import {doc, setDoc} from 'firebase/firestore'

const { TextArea } = Input;

const UploadCard = (props)=>{
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [fileList,setFileList] = useState([]);
    const [isChecked,setChecked] = useState(false);
    const [form] = Form.useForm();
    //upload
    const createSubmit = async (value) => {
        setConfirmLoading(true);
        let imgLink='';
        for(let i=0;i<fileList.length;i++){
            const storageRef = ref(storage,`${value.topic}/${value.title}/${fileList[i].name}`);
            const uploadTask = await uploadBytes(storageRef, fileList[i])
            if(fileList[i].name.split('.')[1]==='png'){
                imgLink = await getDownloadURL(storageRef)
            }
        }
        const newDoc = doc(db,value.topic,value.title)
        const docData = {
            title: value.title,
            note: value.note?value.note.replace(/(\r\n|\r|\n)/g, '\r\n'):'',
            mockup: value.mockup?value.mockup:'',
            date: new Date(),
            fullPath: `${value.topic}/${value.title}/`,
            imgLink: imgLink,
            fonts: value.fonts?value.fonts.filter(f=>f!=undefined):'',
            completed: false
        }
        await setDoc(newDoc,docData)
        props.refresh();
        props.setOpen(false);
        setConfirmLoading(false);
        setFileList([]);
        setChecked(false);
        form.resetFields();
    };

    const createCancel = () => {
        if(confirmLoading) return
        props.setOpen(false);
    };

    const uploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file,fileList) => {
            setFileList([])
            setFileList([...fileList]);
            return false;
        },
        fileList
    };


    return(

    <Modal
      title="Upload New File"
      open={props.modalOpen}
      onOk={form.submit}
      confirmLoading={confirmLoading}
      onCancel={createCancel}
    >
        <Form form={form} onFinish={createSubmit}>
            <Row gutter={10}>
                <Col span={16} >
                    <Form.Item
                        name="title"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                            },
                            {
                                validator: (_, value) => {
                                    if (!props.items.includes(value)) {
                                      return Promise.resolve();
                                    } else {
                                      return Promise.reject('Some message here');
                                    }
                                },
                                message:'This name has been used'
                            }
                        ]}
                    >
                        <Input 
                            placeholder='Title'
                        />
                    </Form.Item>
                </Col>
                <Col span={8} >
                    <Form.Item
                    
                    name='topic'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                        },
                    ]}>
                        <Select
                            showSearch
                            placeholder = 'Topics'
                            options={props.options}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name='note'>
                <TextArea autoSize={{minRows:3}} placeholder='Notes'/>
            </Form.Item>

            <Form.Item
                name="mockup"
                rules={[
                    {
                        type:'url',
                        message:'This is not a valid link'
                    }
                ]}
            >
                <Input addonBefore={<LinkOutlined />} placeholder='Mockup link'/>
            </Form.Item>
            <Form.List name="fonts">
                {(fields, { add, remove }) => (
                    <Col>
                    {fields.map(({ key, name, ...restField }) => (
                        <Row gutter={5}
                        key={key}
                        align="baseline"
                        >
                        <Col span={22}>
                            <Form.Item
                                {...restField}
                                name={name}
                                rules={[
                                {
                                    type: 'url',
                                    message: 'This is not a valid link',
                                },
                                ]}
                            >
                                <Input addonBefore={<LinkOutlined />} placeholder="Enter font link" />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Button type='text' onClick={() => remove(name)} icon={<MinusCircleOutlined style={{fontSize:'1rem'}}/>}/>
                            
                        </Col>
                        </Row>
                    ))}
                    <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add fonts
                        </Button>
                    </Form.Item>
                    </Col>
                )}
            </Form.List>
            <Form.Item 
                name="upload"
                hasFeedback
                rules={[
                    {
                        validator: (_, value) => {
                            console.log(value)
                            if (value.fileList.length>0) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject('Some message here');
                            }
                        },
                        message:'Upload file cannot be empty'
                    }
                ]}
            >
                <Upload
                    {...uploadProps}
                    // action={handleUpload}
                    listType="picture"
                    multiple
                    // directory
                >
                    <Button icon={<UploadOutlined />}>Upload files</Button>
                </Upload>
            </Form.Item>
        </Form>
    </Modal>
    )
}

export default UploadCard;