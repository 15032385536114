import './App.css';
import { CloudUploadOutlined, SearchOutlined, FolderFilled, FolderAddOutlined} from '@ant-design/icons';
import { Badge, Button, Form, Tag } from 'antd';
import { Flex, Layout, Input, Menu, ConfigProvider, List, Typography, Avatar, Modal, Pagination, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import UploadCard from './UploadCard';
//firebase
import firebase, {ref,storage,db} from './Firebase';
import { listAll, uploadBytes, getMetadata } from 'firebase/storage';
import { collection, doc, getDocs, query, orderBy} from 'firebase/firestore'
import FileItem from './FileItem';

const {Title} = Typography;
const { Header, Footer, Sider, Content } = Layout;
const headerStyle = {
  height: '80px',
  backgroundColor: '#f9f9f9',
  marginBottom: 20,
  boxShadow: '0px 5px 20px -20px gray',
};
const contentStyle = {
  width: 'calc(100vw - 200px)',
  // maxWidth: '1000px',
  padding: '0px 30px',
  backgroundColor: '#f3f4f7',
  // height: 'calc(100vh - 100px)',
};
const siderStyle = {
  backgroundColor: '#f3f4f7',
  height: 'calc(100vh - 100px)',
  // boxShadow: '-25px -20px 30px 5px gray'
};
const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  backgroundColor: '#f3f4f7',
  color: '#bec7d9'
};
const searchStyle = {
  width:'calc(100% - 200px)',
  overflow: 'hidden',
  backgroundColor: '#f3f4f7',
  border: 'none',
  margin: '10px 30px'
};

const CustomTheme={
  // algorithm:  defaultAlgorithm,
  components: {
    Form:{
      itemMarginBottom:10,
    },
    Menu:{
      itemSelectedBg:'#e6ebf8',
      groupTitleColor:'',
    }
  },
  token: {
    colorPrimaryHover:'#4376f9',
    colorPrimary: '#4376f9',
    colorTextBase: '#abb4c2',
    colorError: '#e66960',
    fontFamily: "Lato",
  }
}

function App() {
  // const { defaultAlgorithm, darkAlgorithm } = theme;

  const [open, setOpen] = useState(false);
  const [menuData,setMenuData] = useState([]);
  const [currentPath, setCurrentPath] = useState('Home');
  const [Items,setItems] = useState([])
  const [filtedItems,setFiltedItems] = useState([])
  const [refreshItem,setRefresh] = useState(false)
  const [refreshMenu,setRefreshMenu] = useState(false)
  const [searchQuery,setSearchQuery] = useState('')
  const [itemNumber,setItemNumber] = useState()
  const [currentPage,setCurrentPage] = useState(1)
  const [isItemLoading,setItemLoading] = useState(true)
  const [cookies, setCookie] = useCookies(['date']);
  const [lastTime, setLastTime] = useState();
  const itemPerPage = 8;

  const [newFolderForm] = Form.useForm();
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [newFolderLoaing, setNewFolderLoading] = useState(false);

  const MenuLabel = (props)=>{
    return(
      <Flex align='center' justify='space-between' style={{width:'100%'}}>
        <div style={{width:'85%',textOverflow:'ellipsis',overflow:'hidden'}}>
          <FolderFilled/>
          <span style={{marginLeft:'8px'}}> {props.name} </span>
        </div>

          <div style={{width:'10%',textAlign:'right'}}>{props.fileCount} </div>
      </Flex>
    )
  }

  useEffect(()=>{
    const getDir = async ()=>{
      const listRef = ref(storage, '');
      const listDir = async (listRef)=>{
        var dirData = [];
        const listed = await listAll(listRef);
        const prefixes = (listed).prefixes
        for(let i=0;i<prefixes.length;i++){
          const fileCount = (await listAll(ref(storage,prefixes[i].fullPath))).prefixes.length;
          const newDir = {
            value: prefixes[i].name,
            label: <MenuLabel name={prefixes[i].name} fileCount={fileCount}/> ,
            key: prefixes[i].fullPath,
          }
          dirData.push(newDir)
        }
        return dirData;
      };
      const array = await listDir(listRef);
      setMenuData([...array]);
    }
    getDir();
  },[refreshMenu]);

  useEffect(()=>{
    
    const getItem = async ()=>{
      setItemLoading(true)
      const docData = query(collection(db, currentPath), orderBy("date", "desc"));
      const docSnap = await getDocs(docData);
      const tempList = []
      docSnap.forEach(doc => {
        tempList.push(doc.data())
      });
      setItems(tempList)
      setFiltedItems([...tempList])
      setSearchQuery('')
      setItemLoading(false)
    }
    getItem();
    setLastTime(cookies.date)
    setCookie('date',new Date())
  },[currentPath,refreshItem])

  useEffect(()=>{
    setItemNumber(filtedItems.length)
  },[filtedItems])

  const showModal = () => {
    setOpen(true);
  };

  const onSearch = (e)=>{
    setSearchQuery(e.target.value)
    const tempArray = Items.filter((item)=>item.title.toLowerCase().includes(e.target.value.toLowerCase()))
    setFiltedItems(tempArray);
  }

  const onPageChange = (page,size)=>{
    setCurrentPage(page)
  }

  return (

    <ConfigProvider
      theme={CustomTheme}
    >

    <div className="App">
      <Layout style={layoutStyle}>
        <Header style={headerStyle}>
            <Flex style={{alignItems: 'center',justifyContent:'space-between',maxWidth:'1000px',margin:'auto',padding:'10px 0px'}} gap='small'>
              <Flex style={{width:'200px',whiteSpace:'nowrap',overflow:'hidden'}}  align='center' gap={5} >
                  <Avatar shape='square' size={56} src={'https://icons.iconarchive.com/icons/raindropmemory/laboratory/256/Storage-Box-icon.png'} /> 
                  <span className='titleColor' style={{margin: '0',justifyItems:'center',fontSize:'1.6rem',fontWeight:'700'}}>Yabu's Box</span> 
              </Flex>
              <Input style={searchStyle} value={searchQuery} onChange={onSearch} prefix={<SearchOutlined style={{color:'gray'}}/>} size="large" placeholder="Search"/>
            </Flex>

        </Header>
        <Layout style={{maxWidth:'1000px',margin:'auto'}}>
          <Sider width={'200px'} style={siderStyle}>
            <Flex justify='space-between'>
              <Button  style={{margin:'0 0 10px 0',fontWeight:'600',padding:'20px 20px',width:'40%'}}  onClick={()=>{setOpenNewFolder(true)}}  type="dashed" icon={<FolderAddOutlined />}>
                      Topics
              </Button>
              <Button  style={{margin:'0 0 10px 0',fontWeight:'600',padding:'20px 20px',width:'55%'}} onClick={showModal} type="primary" icon={<CloudUploadOutlined />}>
                      New Files
              </Button>
            </Flex>
            <Menu
                style={{height:'95%',backgroundColor:'transparent',border:'none',fontWeight:'600'}}
                // items={[{
                //   label: 'Category',
                //   type: 'group',
                //   children: menuData}]}
                items={menuData}
                onClick={(e)=>{
                  setCurrentPath(e.key)
                }}
                defaultSelectedKeys={['Home']}
            />
          </Sider>
          <Content style={contentStyle}>

            <Skeleton loading={isItemLoading} active >
            <List
              style={{width:'100%'}}
              dataSource={filtedItems.slice(itemPerPage*(currentPage-1),itemPerPage*(currentPage+1)-itemPerPage)}
              renderItem={(item) => (
                <FileItem 
                  title={item.title}
                  note={item.note}
                  mockupLink={item.mockup}
                  fullPath={item.fullPath}
                  date={item.date}
                  img={item.imgLink}
                  completed={item.completed}
                  currentPath={currentPath}
                  fonts={item.fonts}
                  refresh = {()=>setRefresh(!refreshItem)}
                  lastTime={lastTime}
                />
              )}
            />
            </Skeleton>
            <Pagination 
              style={{margin:'auto',width:'fit-content',display:(itemPerPage>itemNumber)?'none':''}} 
              onChange={onPageChange} 
              showSizeChanger={false}
              pageSize={itemPerPage}
              defaultCurrent={1} 
              total={itemNumber}
            />
          </Content>
        </Layout>
      </Layout>

        <UploadCard 
            modalOpen = {open}
            setOpen = {(e)=>{setOpen(e)}}
            currentPath = {currentPath}
            refresh = {()=>setRefresh(!refreshItem)}
            items = {Items.map(item=>item.title)}
            options = {menuData.map(tree=>{
              return({
                value: tree.value,
                label: tree.label
              })
            })}
        />

        <Modal
          title="New Topic"
          open={openNewFolder}
          onOk={newFolderForm.submit}
          confirmLoading={newFolderLoaing}
          onCancel={()=>{setOpenNewFolder(false)}}
          width={300}
        >
          <Form 
            form={newFolderForm} 
            onFinish={async (value)=>{
              setNewFolderLoading(true);
              const storageRef = ref(storage,`${value.topic}/holding`);
              await uploadBytes(storageRef, "a");
              setRefreshMenu(!refreshMenu);
              setNewFolderLoading(false);
              setOpenNewFolder(false);
            }}
          >
            <Form.Item
              name='topic'
              rules={[{
                required: true,
              }]}
            >
              <Input placeholder="Topic's name"></Input>
            </Form.Item>
          </Form>
        </Modal>
    </div>
    </ConfigProvider>
  );
}

export default App;
