import React, { useEffect, useState } from 'react';
import { DownloadOutlined, LinkOutlined, DeleteFilled, ClockCircleOutlined, CheckCircleFilled, MessageOutlined } from '@ant-design/icons';
import { List, Avatar, Button, Popover, Image, Space, Popconfirm, Spin, Badge} from 'antd';
import { downloadFolderAsZip } from './Firebase';
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { ref, deleteObject,listAll } from "firebase/storage";
import { db,storage } from './Firebase';
import TextArea from 'antd/es/input/TextArea';


const FileItem = (props)=>{
    const date = new Date(props.date.seconds*1000)
    const [delPpen, setDelOpen] = useState(false);
    const [delConfirmLoading, setDelConfirmLoading] = useState(false);
    const [completedLoading, setcompletedLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [isImgLoading, setImgLoading] = useState(true);

    const handleLoading = () => {
    }
    useEffect(()=>{
        setImgLoading(true);
    },[props.img])
    
    useEffect(()=>{
        setIsCompleted(props.completed);
    },[props.refresh])
    
    const showPopconfirm = () => {
        setDelOpen(true);
    };
    
    const handleOk = async () => {
        setDelConfirmLoading(true);
        const fileRef = ref(storage, props.fullPath);
        await deleteDoc(doc(db, props.currentPath, props.title));
        await listAll(fileRef)
            .then((res) => {
                res.items.forEach((itemRef) => {
                    deleteObject(itemRef);
                });
            }).catch((error) => {
                // Uh-oh, an error occurred!
            });

        setDelOpen(false);
        setDelConfirmLoading(false);
        props.refresh()
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setDelOpen(false);
    };
    const handleCompleted = async()=>{
        setcompletedLoading(true);
        await updateDoc(doc(db,props.currentPath,props.title),{
            completed: !isCompleted
        })
        setIsCompleted(!isCompleted)
        setcompletedLoading(false);
    }
    const completedBtn = {
        color: '#69C33D',
        backgroundColor: '#ECF5E8',
        borderColor: "#A9E68C",
    }

    const tagBtn = {
        backgroundColor: 'transparent',
    }

    const Tags = ()=>{
        return(
            <Space>
                <Button onClick={handleCompleted} loading={completedLoading} 
                        style={isCompleted?completedBtn:tagBtn} size='small' icon={isCompleted?<CheckCircleFilled />:<ClockCircleOutlined />}>
                    {isCompleted?'Done':date.toLocaleDateString("en-US")}
                </Button>
                <Button style={tagBtn} disabled={props.mockupLink===''?true:false} href={props.mockupLink} target='_blank' type='dashed' size='small' icon={<LinkOutlined />}>
                    Mockup
                </Button>
                {props.fonts&&props.fonts.map((font,index)=>
                    (
                        <Button style={tagBtn} href={font} target='_blank' type='dashed' size='small' icon={<LinkOutlined />}>
                            Font {index+1}
                        </Button>
                    )
                )}
            </Space>
        )
    }
    const note = ()=>{
        return(
            <Popover 
                style={{maxWidth:'100px'}}
                content={
                <TextArea 
                    autoSize={{minRows: 2, maxRows: 6,}} 
                    disabled value={props.note} 
                    style={{color:'#8c93a2', maxHeight:'5lh', resize:'none',border:'none'}}/>}
                placement="left"
                title="Note"
                trigger='click'
            >
                <Button disabled={!props.note} type='link' shape="square" icon={<MessageOutlined />} ></Button>
            </Popover>
        )
    }

    const itemStyle = {
        backgroundColor:'#f9f9f9',
        borderRadius: '10px',
        marginBottom: '10px',
        boxShadow: '0px 5px 20px -20px gray',
        padding: '10px 15px',
        width:'100%'
    }
    const itemStyleNew = {
        backgroundColor:'#f9f9f9',
        borderRadius: '10px',
        marginBottom: '10px',
        boxShadow: '0px 5px 20px -18px #4376f9',
        padding: '10px 15px',
        width:'100%',
        transform:'scale(1.02)',
    }

    const DateCompare = ()=>{
        const fdate = props.date.seconds
        const udate = Date.parse(props.lastTime)/1000
        return (fdate - udate) < 0
    }

    return(
        <Badge.Ribbon style={{display:DateCompare()&&'none',transform:'translate(-7px,0px)'}} text='New' color='#e66960' placement='start'>
        <List.Item 
            style={!DateCompare()?itemStyleNew:itemStyle}
            actions={[
                note(),
                <Popconfirm
                    title="Delete Confirmation"
                    description="Are you sure to delete this file?"
                    open={delPpen}
                    onConfirm={handleOk}
                    okButtonProps={{
                        loading: delConfirmLoading,
                    }}
                    onCancel={handleCancel}
                >
                    <Button onClick={showPopconfirm} danger type='link' shape="square" icon={<DeleteFilled />} />
                </Popconfirm>,
                <Button className='titleColor' onClick={()=>{downloadFolderAsZip(props.fullPath)}} type='text' shape="square" icon={<DownloadOutlined />} > Download</Button>,
            
            ]}>
                <List.Item.Meta
                    avatar = {
                        <Popover 
                            placement="left"
                            content={
                                    <Image
                                    width={200}
                                    src={props.img}/>
                            }
                        >
                            <Spin spinning={isImgLoading} size="small">
                                <Avatar onLoad={()=>{setImgLoading(false)}} shape='square' size={56} src={props.img}/>
                            </Spin>
                        </Popover>
                    }
                    title= {<span className='titleColor'>{props.title}</span>}
                    description= {Tags()}
                />

        </List.Item>
        </Badge.Ribbon>
    )
}

export default FileItem