//firebase sdks
import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, uploadBytes, getDownloadURL} from "firebase/storage";
import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
//firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCa6nSqcgmISV77MX-4BGIEN8Ykst7Ruek",
  authDomain: "svgstorage-72c5c.firebaseapp.com",
  projectId: "svgstorage-72c5c",
  storageBucket: "svgstorage-72c5c.appspot.com",
  messagingSenderId: "983419878219",
  appId: "1:983419878219:web:a9e5d8dc69149be09e46ce",
  storageBucket: "gs://svgstorage-72c5c.appspot.com"
};

//initialize firebase
const app = initializeApp(firebaseConfig);
//storage 
const storage = getStorage();
const db = getFirestore(app);

export default firebase;
export {ref,app,storage,db}
export const downloadFolderAsZip = async (path) => {
  const jszip = new JSZip();
  const storage = getStorage();
  const folderRef = ref(
    storage,
    path
  );
  const folder = await listAll(folderRef);

  const promises = folder.items
    .map(async (item) => {
      const fileRef = ref(storage, item.fullPath);
      const fileBlob = await getDownloadURL(fileRef).then((url) => {
        return fetch(url).then((response) => response.blob());
      });
      jszip.file(item.name, fileBlob);
    })
    .reduce((acc, curr) => acc.then(() => curr), Promise.resolve());
  try{
    await promises;
  }catch(err){
    console.log(err)
  }
  const blob = await jszip.generateAsync({ type: 'blob' });
  saveAs(blob, `SVG_${folderRef.name}.zip`);
};
